'use strict';
import { gMapstyle, gMapGrayStyle, gMapBlackStyle } from './MapStyle';

declare var google: any;

export default class Atarica {

	protected MY_MAPTYPE_ID: string = '';
	protected mapPos;
	protected map;

	protected resiezeTimer = null;
	protected oldWid: number = 0;

	constructor() {
		this.init();
	}
	protected init() {
		this.MY_MAPTYPE_ID = 'atarica_style';
		this.resize();
	}

	public load() {
		this.resize();
		$(window).on('resize',() => {this.resize();});

		$('.header .btn_menu a').on('click', (event) => {
			event.preventDefault();
			$('.header .btn_menu').toggleClass('open');
			$('.header .menu_sp').toggleClass('open');
			$('html').toggleClass('open');
		});
		$('.header .menu_sp .g_menu li a').on('click',(event) => {
			event.preventDefault();
			const wid = $(window).innerWidth();
			const offset = $($(event.currentTarget).attr('href')).offset().top;
			const mgn = wid * 0.18;
			$('html,body')
				.stop(true,false)
				.delay(500)
				.animate({scrollTop: (offset-mgn)+'px'},500,'easeInOutExpo');

			$('.header .btn_menu').removeClass('open');
			$('.header .menu_sp').removeClass('open');
			$('html').removeClass('open');
		});

		const hash = location.hash;
		if (hash !== '' && hash.substr(0,1) === '#') {
			setTimeout(()=>{
				const wid = $(window).innerWidth();
				const offset = $(hash).offset().top;
				const mgn = wid < 768
					? wid * 0.18
					: 0;
				$('html,body')
				.stop(true,false)
				.delay(500)
				.animate({scrollTop: (offset-mgn)+'px'},500,'easeInOutExpo');
			},600);
		}
	}

	public resizeBox() {
		const w = $(window).innerWidth();
		const minW = w >= 768 ? 0.3 : 0.45;
		const maxW = 0.95;
		$('.frame').each((i,o) => {
			const wid = minW + Math.random() * (maxW-minW);
			const float = Math.random() >= 0.5 ? 'left' : 'right';
			$(o).css({
				//width: Math.round(wid) + 'px',
				width: wid*100 + '%',
				float: float,
			});
			$(o).find('h3, .address').css({
				'text-align':float,
			});
		})
	}

	public mapInit () {
		this.mapPos = new google.maps.LatLng(35.6844327,139.7364264);
		//
		let mapStyle = gMapBlackStyle;
		const rnd = Math.random();
		if(rnd <= 0.33 ) {
			mapStyle = gMapstyle;
		} else if (rnd <= 0.66) {
			mapStyle = gMapGrayStyle;
		}
		var featureOpts = mapStyle;
		var mapOptions = {
			zoom: 16,
			center: this.mapPos,
			mapTypeControlOptions: {
				mapTypeIds: [google.maps.MapTypeId.ROADMAP, this.MY_MAPTYPE_ID]
			},
			mapTypeId: this.MY_MAPTYPE_ID,
			scrollwheel: false
		};
		this.map = new google.maps.Map(document.getElementById('gmap'),mapOptions);

		/*var marker = new google.maps.Marker({
			position: komae,
			map: map,
		});*/

		var styledMapOptions = {
			name: 'atarica Map'
		};
		var customMapType = new google.maps.StyledMapType(featureOpts, styledMapOptions);
		this.map.mapTypes.set(this.MY_MAPTYPE_ID, customMapType);

		var prot = location.protocol;
		var host = location.host;
		var pathname = location.pathname.replace(/jp\/$/,'');
		console.log('host:', prot,  host, pathname);

		var image = {
			url : prot + '//' + host + pathname + 'images/point.svg',
			scaledSize : new google.maps.Size(53, 68)
			// ↑ここで画像のサイズを指定
		}
		var myLatLng = new google.maps.LatLng(35.6844327,139.7364264);
		var beachMarker = new google.maps.Marker({
			position: myLatLng,
			map: this.map,
			icon: image
		});
	}

	protected resize() {
		const wid = $(window).innerWidth();
		if(this.oldWid !== wid) {
			if (this.resiezeTimer !== null) {
				clearTimeout(this.resiezeTimer);
				this.resiezeTimer = null;
			}
			this.resiezeTimer = setTimeout(() => {
				this.resiezeTimer = null;
				this.resizeBox();
			}, 500);
			//
			this.oldWid = wid;
		}
	}
}
