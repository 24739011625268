'use strict';
declare var google: any;

import Atarica from "./Atarica";

class Main {
	public atarica:Atarica;
	public onDomContentsLoaded(event:any = null) {
		this.atarica = new Atarica();
	}
	public onContentsLoad(event:any = null) {
		this.atarica.load();
	}
	public mapInit() {
		this.atarica.mapInit();
	}
}

var main = new Main();
window['main'] = main;
if (document.readyState !== 'loading') {
	main.onDomContentsLoaded();
} else {
	document.addEventListener('DOMContentLoaded', function(){window['main'].onDomContentsLoaded();});
}
window.addEventListener('load',function(){window['main'].onContentsLoad();});
google.maps.event.addDomListener(window, 'load', function(){window['main'].mapInit();});
